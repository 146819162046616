import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './assets/fonts/fonts.css';
// import './responsive.scss';
import './utility.scss';
import './App.scss';
import './components/AnalyticsStudio/index.scss';
import { createAxiosInterceptor } from './axios-interceptor';
import Appbar from './components/Appbar.jsx'


createAxiosInterceptor();

const AnalyticsStudio = React.lazy(() =>
	import('./components/AnalyticsStudio')
);

const CreateDataReport = React.lazy(() =>
	import('./components/AnalyticsStudio/CreateDataReport')
);

const DatabaseSelection = React.lazy(() =>
	import(
		'./components/AnalyticsStudio/CreateDataReport/components/DatabaseSelection'
	)
);

const DatabaseSelectionUpload = React.lazy(() =>
	import(
		'./components/AnalyticsStudio/CreateDataReport/components/DatabaseSelectionUpload'
	)
);

const DataMapping = React.lazy(() =>
	import('./components/AnalyticsStudio/CreateDataReport/components/DataMapping')
);

const DataMappingPreview = React.lazy(() =>
	import('./components/AnalyticsStudio/CreateDataReport/components/DataMappingPreview')
);

const DataReport = React.lazy(() =>
	import('./components/AnalyticsStudio/DataReport')
);

const ComponentLoader = <h3 className='text-center mt-20'>Loading...</h3>;

function App() {
	return (
		<div className='app'>
			<Appbar authReducer={{}} handleLogoutClick={() => null} menuData={[]}></Appbar>
			<div className='app-content'>
				<React.Suspense fallback={ComponentLoader}>
					<Routes>
						<Route path='/' element={<AnalyticsStudio />} />
						<Route path='create-data-report' element={<CreateDataReport />}>
							<Route
								path='database-selection'
								element={<DatabaseSelection />}
							/>
							<Route
								path='database-selection-upload'
								element={<DatabaseSelectionUpload />}
							/>
							<Route path='data-mapping' element={<DataMapping />} />
							<Route path='data-mapping-preview' element={<DataMappingPreview />} />
						</Route>
						<Route path='data-report' element={<DataReport />} />
					</Routes>
				</React.Suspense>
			</div>
		</div>
	);
}

export default App;

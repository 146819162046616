import axios from 'axios';
import { CONFIG } from '../../../config';

export async function fetchMetaDataAnalyticStudioService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}as/getmetadata?_id=${payload.dataSource}`)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function fetchDataAnalyticStudioService(payload) {
	if (payload.export == 'true') {
		return axios
			.post(`${CONFIG.api_base_url}as/getdata`, payload, {
				responseType: 'blob',
			})
			.then((response) => {
				var x = response.data;
				var u = URL.createObjectURL(x);
				var a = document.createElement('a');
				a.href = u;
				a.download = response.headers['content-disposition'].split('=')[1];
				a.click();
				window.URL.revokeObjectURL(u);

				return response;
			})
			.catch((err) => err.response);
	}

	return axios
		.post(`${CONFIG.api_base_url}as/getdata`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function getAnalyticStudioService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}as/getanalyticstudio`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function getAnalyticStudioReportService(payload) {
	return axios
		.get(`${CONFIG.api_base_url}as/getanalyticstudioreport`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function getAnalyticStudioSingleReportService(payload) {
	return axios
		.get(
			`${CONFIG.api_base_url}as/getanalyticstudioreport?id=${payload}`,
			payload
		)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function sendEmailForChartService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}as/sendemail`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function saveAnalyticStudioService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}as/saveanalyticstudio`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function saveAnalyticStudioReportService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}as/saveanalyticstudioreport`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function deleteAnalyticStudioService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}as/delete`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function deleteReportAnalyticStudioService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}as/deletereport`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function shareChartService(data) {
	let payload = {
		options: JSON.stringify(data.userOptions),
		filename: 'LineChart',
		b64: true,
	};

	let url = 'https://export.highcharts.com/';

	return axios
		.post(url, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function uploadDataReportFile(payload) {
	return axios
		.post(`${CONFIG.api_base_url}ds/uploadexcel`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}


import React from "react";
import {CssBaseline, AppBar, Toolbar, Grid} from './common/Imports/MuiComponents';
import Logo from "../images/eaciit-white-logo.png";
import UserMenu from "./UserMenu/UserMenu";
import { Link } from "react-router-dom";
import { getParameterByName } from "../helpers/util";

function Appbar(props) {
  const [universalFilterValue, setUniversalFilterValue] = React.useState("");
  const { authReducer, menuData } = props;
  // const loginState = useSelector(state => state.authReducer);
  const loginState = {
    showResetModal: false,
    isFullScreen: getParameterByName("screenSize") === "fullscreen",
    userData: {
      name: "rahul",
    },
  };
  console.log(getParameterByName("screenSize"), "--screenSize");
  const handleChange = (event) => {
    if (event && event.target && event.target.value) {
      setUniversalFilterValue(event.target.value);
    }
  };

  return (
    <React.Fragment>
      {!loginState.showResetModal && !loginState.isFullScreen ? (
        <>
          <CssBaseline />
          {/* {!authReducer.loading && (
        <CustomizedDialogs
          showModal={
            (loginState.showResetModal)
          }
        />
      )} */}
          <AppBar position="fixed" className="app-header" elevation={0}>
            <Toolbar variant="dense">
              <Link to="/">
                <img src={Logo} width="120"></img>
              </Link>
              <Grid
                item
                xs={false}
                sm={7}
                md={7}
                className="flex jc-center"
              ></Grid>
              <Grid
                item
                xs={false}
                sm={3}
                md={3}
                className="flex jc-flex-end"
              ></Grid>
              <Grid item xs={false} sm={1} md={1} className="flex jc-flex-end">
                <>{loginState.userData.name && <UserMenu {...props} />}</>
              </Grid>
            </Toolbar>
          </AppBar>
        </>
      ) : null}
    </React.Fragment>
  );
}

export default Appbar;
